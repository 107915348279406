<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <div>
        <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
          <el-form-item>
            <el-input
                v-model="searchInfo.condition"
                placeholder="输入模糊搜索值"
                class="form-item-width"
                clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="失效时间">
            <el-date-picker
                v-model="expiryTime"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="授权时间">
            <el-date-picker
                v-model="createTime"
                type="datetimerange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
          <el-form-item class="right-btns">
            <el-button icon="el-icon-search" type="primary" @click="onSearch">查询</el-button>
            <el-button @click="onReset">重置</el-button>
            <el-button icon="el-icon-plus" type="primary" @click="dialogVisible = true">授权</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格展示区域 -->
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column
            align="center"
            prop="account"
            width="150"
            label="授权帐号">
        </el-table-column>
        <el-table-column
            align="center"
            width="180"
            label="授权时间">
          <template v-slot:default="scope">
            {{ scope.row.createTime | time }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="180"
            label="生效时间">
          <template v-slot:default="scope">
            {{ scope.row.issuedTime | time }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="180"
            label="失效时间">
          <template v-slot:default="scope">
            {{ scope.row.expiryTime | time }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="300"
            prop="description"
            label="描述">
        </el-table-column>
        <el-table-column
            align="center"
            prop="extra"
            label="其他信息">
        </el-table-column>
        <el-table-column
            label="操作"
            width="100"
            align="center">
          <template v-slot:default="scope">
            <span @click="onDownload(scope.row.fileName)" class="update-btn">下载</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <div>
        <Pagination
            :total="dataTotal"
            :page="searchInfo.page"
            :limit="searchInfo.size"
            @pagination="onChangePages"
        />
      </div>
    </div>

    <!-- 新增账号弹框 -->
    <el-dialog
        title="添加授权"
        :visible.sync="dialogVisible"
        top="5vh"
        width="400px"
    >
      <el-form
          ref="form"
          class="dialog-form"
          :model="licenseRecord"
          label-width="80px"
      >
        <el-form-item>
          <el-upload
              :action="uploadUrl"
              :headers="headers"
              name="avatarFile"
              :limit="1"
              :on-exceed="exceedFile"
              :on-success="handleAvatarSuccess"
              :on-error="handleAvatarError"
              :file-list="fileList">
            <el-button
                icon="el-icon-upload2"
                type="primary"
                style="width: 150px; height: 40px">
              上传授权文件
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="生效时间">
          <el-date-picker
              v-model="licenseRecord.issuedTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="失效时间">
          <el-date-picker
              v-model="licenseRecord.expiryTime"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
              v-model="licenseRecord.description"
              placeholder="请输入描述"
              type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onAddUser">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import request from "@/api/request";
import Cookies from "js-cookie";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      uploadUrl: "/license_server/license-record/upload",
      headers: {},
      // 查询数据
      searchInfo: {
        condition: '',
        expiryBeginTime: null,
        expiryEndTime: null,
        beginTime: null,
        endTime: null,
        page: 1,
        size: 10,
      },
      // 弹框表单信息
      licenseRecord: {
        issuedTime: null,
        expiryTime: null,
        description: null,
        extra: null
      },
      issuedTime: [],
      expiryTime: [],
      createTime: [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 999)],
      // 表格数据
      tableData: [],
      // 数据总数
      dataTotal: 0,
      // 是否展示弹框
      dialogVisible: false,
      fileList: [],
      isUpload: false,

    }
  },
  mounted() {
    // 查询数据
    this.onSearch()
    this.headers = {"token": Cookies.get("Token")};
  },
  methods: {
    // 上传图片触发
    handleChangeImg(imgList) {
      console.log(imgList)
    },
    // 查询
    onSearch() {
      this.searchInfo.page = 1
      this.onGetDataList()
    },
    // 重置
    onReset() {
      this.searchInfo = {
        page: 1,
        size: 10
      }
      this.dialogVisible = false
      this.licenseRecord = {}
      this.fileList = []
      this.onSearch()
    },
    onDownload(data) {
      const a = document.createElement("a");
      a.href = data;
      a.target = "_blank";
      a.click();
    },
    // 新增方法
    onAddUser() {
      if (this.isUpload) {
        if (!this.licenseRecord.issuedTime) {
          this.licenseRecord.issuedTime = new Date().getTime();
        }
        if (!this.licenseRecord.expiryTime) {
          this.msgError('请选择失效时间')
          return
        }
        request({
          url: "/license-record/generate",
          method: "post",
          data: JSON.stringify(this.licenseRecord)
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.onDownload(res.data.data);
                this.onReset();
              } else {
                this.msgError(res.data.message);
              }
            })
            .catch((error) => {
              console.error(error)
            });
      } else {
        this.msgError("请先上传授权文件");
      }
    },
    // 分页方法
    onChangePages(pageInfo) {
      const {page, limit} = pageInfo
      this.searchInfo.page = page
      this.searchInfo.size = limit
      this.onGetDataList()
    },
    // 核心查询数据方法
    onGetDataList() {
      if (this.expiryTime != null && this.expiryTime.length > 1) {
        this.searchInfo.expiryBeginTime = this.expiryTime[0];
        this.searchInfo.expiryEndTime = this.expiryTime[1];
      } else {
        this.searchInfo.expiryBeginTime = null;
        this.searchInfo.expiryEndTime = null;
      }
      if (this.createTime != null && this.createTime.length > 1) {
        this.searchInfo.beginTime = this.createTime[0];
        this.searchInfo.endTime = this.createTime[1];
      } else {
        this.searchInfo.beginTime = null;
        this.searchInfo.endTime = null;
      }

      // 查询数据方法
      request({
        url: "/license-record/search/" + this.searchInfo.page + "/" + this.searchInfo.size,
        method: "post",
        data: JSON.stringify(this.searchInfo)
      })
          .then((res) => {
            this.tableData = res.data.data.records;
            this.dataTotal = res.data.data.total;
          })
          .catch((error) => {
            console.error(error)
          });
    },
    exceedFile() {
      this.msgError("每次只能上传一个文件！");
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 200) {
        this.isUpload = true;
        this.licenseRecord.extra = res.data;
      } else {
        this.msgError(res.message)
        this.fileList = [];
      }
    },
    handleAvatarError(res, file) {
      console.log("onError" + res.message);
      this.fileList = [];
    },
  },
  filters: {
    // 转化时间戳
    time(value) {
      let date = new Date(value)
      let Y = date.getFullYear() + '-'
      let M =
          (date.getMonth() + 1 < 10
              ? '0' + (date.getMonth() + 1)
              : date.getMonth() + 1) + '-'
      let D =
          (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      let h =
          (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      let m =
          (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
          ':'
      let s =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    },
  },
}
</script>

<style lang="less" scoped>
.form-item-width {
  width: 250px;
}

.right-btns {
  position: absolute;
  right: 20px !important;
}

.el-form-item {
  margin-bottom: 4px !important;
}

.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}

.margin-top {
  margin-top: -15px;
}

.update-btn {
  cursor: pointer;
  color: #409eff;
}

.delete-btn {
  cursor: pointer;
  color: #f56c6c;
  margin-left: 20px;
}

.dialog-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>